
import { useRoute } from 'vue-router';
import { computed, ref } from '@vue/reactivity';
import { watch } from '@vue/runtime-core';
import {
  ChevronRightIcon,
  UserIcon,
  MinusIcon,
  MailIcon,
  PhoneIcon,
  ReceiptRefundIcon,
} from '@heroicons/vue/outline';
import { RefreshIcon, ExclamationIcon } from '@heroicons/vue/solid';
import store from '@/store';
import { Trip, Passenger } from '@/models';
import { getTrip } from '@/api/trips.api';
import SystemStatusBadge from '@/components/SystemStatusBadge.vue';
import {
  titlize,
  formatDate,
  formatCurrency,
  removeUnderscores,
} from '@/components/utils';
import { isRefundCheckEnqueued, refundCheckTrip } from '@/api/refund.api';

export default {
  name: 'trip',
  components: {
    ChevronRightIcon,
    UserIcon,
    MinusIcon,
    MailIcon,
    PhoneIcon,
    RefreshIcon,
    ExclamationIcon,
    ReceiptRefundIcon,
    SystemStatusBadge,
  },
  setup() {
    const route = useRoute();
    const tripId = ref(route.params.id as string);
    const trip = ref({} as Trip);
    const loading = ref(true);
    const error = ref('');
    const showRefundButton = ref(false);
    const user = store.state.user;

    const fetchTrip = async () => {
      try {
        loading.value = true;
        const response = await getTrip(tripId.value);

        if (
          Object.keys(response.data?.orders).length === 0 &&
          Object.keys(response.data?.basket).length !== 0
        )
          response.data.orders = response.data.basket;

        trip.value = response.data as Trip;
        error.value = '';

        if ('integrationId' in response.data) {
          const integration = store.findIntegrationById(
            response.data.integrationId
          );
          integration && store.setActiveIntegration(integration);
        }
      } catch (e) {
        trip.value = {} as Trip;
        error.value = `Error: could not fetch trip.`;
      } finally {
        loading.value = false;
      }
    };

    const refundCheckStatuses: Record<string, any> = computed(() => {
      const statuses = {};
      if (trip) {
        const refundCheckItems = trip.value.refundCheckItems ?? [];
        refundCheckItems.forEach((refundCheckItem) => {
          const itemId = refundCheckItem.itemId;

          Object.assign(statuses, {
            [itemId]: {
              progressStatus: refundCheckItem.status,
              refundResult: refundCheckItem.refundStatus,
            },
          });
        });
      }
      return statuses;
    });

    const ticketOrders = computed(() => {
      const orders: any[] = [];
      if (trip) {
        for (var orderId in trip.value.orders) {
          const ticketOrder = trip.value.orders[orderId];
          if (ticketOrder.productType == 'ticket') {
            const ticket = trip.value.tickets?.filter(
              (t: any) => t.ticketId == ticketOrder.ticketId
            )[0];
            ticketOrder.ticket = ticket;
            orders.push(trip.value.orders[orderId]);
          }
        }
      }
      return orders;
    });

    const ancillaryOrders = computed(() => {
      const orders: any[] = [];
      if (trip) {
        for (var orderId in trip.value.orders) {
          const ticketOrder = trip.value.orders[orderId];
          if (ticketOrder.productType != 'ticket') {
            const ticket = trip.value.tickets?.filter(
              (t: any) => t.ticketId == ticketOrder.ticketId
            )[0];
            ticketOrder.ticket = ticket;
            orders.push(trip.value.orders[orderId]);
          }
        }
      }
      return orders;
    });

    const ticketIdsWithProducts = computed(() => {
      const ticketIds: Set<string> = new Set([]);
      if (trip) {
        for (const orderId in trip.value.orders) {
          const productOrder = trip.value.orders[orderId];
          if (productOrder.ticketId) {
            ticketIds.add(productOrder.ticketId);
          }
        }
      }
      return ticketIds;
    });

    const journeyIdsOnTicketWithProducts = computed(() => {
      const journeyIds: Set<string> = new Set([]);
      const ticketIds: Set<string> = new Set([]);
      if (trip) {
        for (const orderId in trip.value.orders) {
          const productOrder = trip.value.orders[orderId];
          if (productOrder.ticketId) {
            ticketIds.add(productOrder.ticketId);
            if (productOrder.ticket) {
              for (const journeyId of productOrder.ticket.journeyIds) {
                journeyIds.add(journeyId);
              }
            }
          }
        }
      }
      return journeyIds;
    });

    const getTripPNRs = computed(() => {
      const pnrs: string[] = [];
      if (trip) {
        trip.value.tickets?.forEach(
          (ticket: { accessDetails: { recordLocator: string } }) => {
            if (pnrs.indexOf(ticket?.accessDetails?.recordLocator) === -1)
              pnrs.push(ticket?.accessDetails?.recordLocator);
          }
        );
      }
      if (pnrs.length) return pnrs.join(', ');
      return 'N/A'; // should never be the case there are no pnrs
    });

    const buildRefundCheckString = function (
      refundResult: string,
      progressStatus: string
    ) {
      if (refundResult != 'unknown') {
        return `Refund ${titlize(refundResult)}`;
      } else {
        return `Refund Check: ${titlize(progressStatus)}`;
      }
    };
    const getFilteredBenefits = function (orderBenefits: any) {
      const benefits: any[] = [];
      orderBenefits.forEach((benefit: any) => {
        if (
          !Object.prototype.hasOwnProperty.call(
            benefit.benefitDetails,
            'possible'
          ) ||
          benefit.benefitDetails.possible == true
        ) {
          benefits.push(benefit);
        }
      });
      return benefits;
    };

    const getAmountString = function (amount: number, decimalPlaces: number) {
      if (amount == 0) {
        return '0';
      }
      return (
        amount.toString().slice(0, -decimalPlaces) +
        '.' +
        amount.toString().slice(-decimalPlaces)
      );
    };

    const getSubItemTotal = function (subItemAmounts: any[]) {
      return subItemAmounts.reduce(
        (partialSum, item) => partialSum + item.amount,
        0
      );
    };

    const getProductOwnership = function (
      productDetails: any,
      passengers: Passenger[]
    ) {
      for (let i = 0; i < passengers.length; i++) {
        var passenger = passengers[i];
        if (passenger.passengerId == productDetails.passengerId) {
          if (passenger?.firstNames && passenger?.surname)
            return (passenger.firstNames + ' ' + passenger.surname).toString();
        }
      }
    };

    const getBookedSeat = function (productDetails: any) {
      if (productDetails?.bookedSeat) {
        var seats = productDetails.bookedSeat[0].data.seats;
        for (let i = 0; i < seats.length; i++) {
          var seat = seats[i];
          if (seat.passengerId == productDetails.passengerId) {
            return seat.seat;
          }
        }
      }
    };

    const getProductSegment = function (productDetails: any, journeys: any[]) {
      for (let i = 0; i < journeys.length; i++) {
        for (let j = 0; j < journeys[i].segments.length; j++) {
          var segment = journeys[i].segments[j];
          if (productDetails.segmentId == segment.segmentId)
            return segment.marketingAirline + segment.marketingFlightNumber;
        }
      }
    };

    // Refund Check
    const shouldShowRefundButton = async () => {
      try {
        if (tripId.value) {
          // Dummy ticket id till we start multi tickets
          const response = await isRefundCheckEnqueued(
            tripId.value,
            'dummy_ticket'
          );

          // show refund button if not already enqueued
          showRefundButton.value = !response.data.isEnqueued;
        } else {
          showRefundButton.value = false;
        }
      } catch (e) {
        console.log(e);
      }
    };

    const refundCheckHandler = async () => {
      try {
        // iteration here over all tickets
        let tickets;
        if (ticketOrders.value.length > 0) {
          tickets = ticketOrders.value;
        } else {
          tickets = trip.value.tickets ?? [];
        }
        tickets.forEach((ticket) => {
          // Don't await. This allows us to immediately disable the button and prevent double clicking.
          if (ticketIdsWithProducts.value.has(ticket.ticketId)) {
            const response = refundCheckTrip(tripId.value, ticket.ticketId);
          }
        });
      } catch (e) {
        console.log(e);
      }
      showRefundButton.value = false;
    };

    watch(
      () => route.params.id,
      async (_value, _prevValue) => {
        tripId.value = route.params.id as string;
        fetchTrip();
        shouldShowRefundButton();
      },
      { immediate: true }
    );

    return {
      user,
      trip,
      error,
      loading,
      fetchTrip,
      ticketOrders,
      ancillaryOrders,
      getFilteredBenefits,
      getAmountString,
      getProductOwnership,
      getBookedSeat,
      getProductSegment,
      getSubItemTotal,
      titlize,
      formatCurrency,
      formatDate,
      removeUnderscores,
      showRefundButton,
      refundCheckHandler,
      refundCheckStatuses,
      buildRefundCheckString,
      journeyIdsOnTicketWithProducts,
      getTripPNRs,
    };
  },
};
